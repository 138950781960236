import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { HeaderH1, SectionH1, UnderlinedLink } from "../helpers/styledComponents";
import { JoinCivicAllianceButton } from "../components/Buttons";

const HeaderContent = <HeaderH1>Page not found</HeaderH1>;

export default () => (
  <Layout mode="dark" headerContent={ HeaderContent } title="Not Found | Civic Alliance" >
    <Section mode="light" fillHeight>
      <SectionH1><UnderlinedLink mode="dark" to="/">Back to Landing Page</UnderlinedLink></SectionH1>
      <JoinCivicAllianceButton mode="dark" center style={{ marginTop: "30px" }} />
    </Section>
  </Layout>
);
